export default (useLog = false) => {
    const { locale, defaultLocale } = useI18n();

    const translate = (link) => {
        const prefixed = link.startsWith('/') ? link : `/${link}`;
        if (useLog) {
            console.log(prefixed);
        }
        if (locale.value === defaultLocale) {
            if (useLog) {
                console.log(`/${defaultLocale}${prefixed}`);
            }
            return `/${defaultLocale}${prefixed}`;
        }

        return prefixed;
    };

    return {
        translate,
    };
};
