<template>
  <nav class="block-navigation-links">
    <div class="block-navigation-links__text">
      {{ text }}
    </div>
    <ul v-if="isArrayNotEmpty(links)" class="block-navigation-links__list">
      <li
        v-for="link in links"
        :key="link.link"
        class="block-navigation-links__list-item"
      >
        <UtilRouteLink
          class="block-navigation-links__list-item-link"
          :link="link.link"
        >
          {{ link.label }}
        </UtilRouteLink>
      </li>
    </ul>
  </nav>
</template>

<script setup>
const props = defineProps({
    textColor: {
        type: String,
        default: 'var(--c-grey--secondary)',
    },

    text: {
        type: String,
        default: '',
    },

    links: {
        type: Array,
        default: () => [],
    },
});

const {
    textColor,
} = toRefs(props);
</script>

<style lang="scss" scoped>
.block-navigation-links {
    display: flex;
    gap: 30px;
}

.block-navigation-links__text {
    color: v-bind(textColor);
    font-family: var(--f-family--teritary);
    font-size: var(--f-size--button);
    font-weight: var(--f-weight--regular);
    line-height: var(--l-height--button);
}

.block-navigation-links__list {
    display: inline-flex;
    color: v-bind(textColor);
    column-gap: 30px;
    font-family: var(--f-family--teritary);
    font-weight: var(--f-weight--regular);
    line-height: var(--l-height--button);
}

.block-navigation-links__list-item {
    position: relative;
    display: flex;
    padding-top: 1px;
}

.block-navigation-links__list-item-link {
    cursor: pointer;
    font-size: var(--f-size--button);
    line-height: var(--l-height--button);
    transition: color 0.1s ease-in-out;

    @include only-hover {
        text-decoration: underline;
    }
}
</style>
