import { defineNuxtPlugin } from '#app/nuxt'
import { LazySvgoArrowDown, LazySvgoArrowLeft, LazySvgoArrowRight, LazySvgoArrowUp, LazySvgoInformationCircle, LazySvgoInstagram, LazySvgoKununu, LazySvgoLine, LazySvgoLinkedin, LazySvgoLogo, LazySvgoPlus, LazySvgoPopupError, LazySvgoPopupSuccess, LazySvgoPopupWarning, LazySvgoTwitter } from '#components'
const lazyGlobalComponents = [
  ["SvgoArrowDown", LazySvgoArrowDown],
["SvgoArrowLeft", LazySvgoArrowLeft],
["SvgoArrowRight", LazySvgoArrowRight],
["SvgoArrowUp", LazySvgoArrowUp],
["SvgoInformationCircle", LazySvgoInformationCircle],
["SvgoInstagram", LazySvgoInstagram],
["SvgoKununu", LazySvgoKununu],
["SvgoLine", LazySvgoLine],
["SvgoLinkedin", LazySvgoLinkedin],
["SvgoLogo", LazySvgoLogo],
["SvgoPlus", LazySvgoPlus],
["SvgoPopupError", LazySvgoPopupError],
["SvgoPopupSuccess", LazySvgoPopupSuccess],
["SvgoPopupWarning", LazySvgoPopupWarning],
["SvgoTwitter", LazySvgoTwitter],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
