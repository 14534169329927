<!-- eslint-disable vue/no-v-html -->
<template>
  <div
    class="atom-heading"
    :class="[
      `is-visible-${visibility}`,
      `is-size-${fontSize}`,
      `is-grid-${grid}`,
      { 'has-decoration': preTitleHasDecoration },
      { 'is-on-grid': isOnGrid },
      {
        'no-letter-spacing': fontFamily === 'var(--f-family--secondary)'
          || fontFamily === 'var(--f-family--secondary-bold)',
      },
    ]"
  >
    <div
      v-if="pretitle"
      class="atom-heading__pretitle"
    >
      <p>{{ pretitle }}</p>
      <div
        v-if="preTitleHasDecoration"
        class="atom-heading__pretitle-decoratrion"
      />
    </div>

    <div v-if="html" class="atom-heading__text">
      <h1 v-if="htmlTag === 'h1'" v-html="html" />

      <h2 v-else-if="htmlTag === 'h2'" v-html="html" />

      <h3 v-else-if="htmlTag === 'h3'" v-html="html" />

      <h4 v-else-if="htmlTag === 'h4'" v-html="html" />

      <h5 v-else-if="htmlTag === 'h5'" v-html="html" />

      <h6 v-else-if="htmlTag === 'h6'" v-html="html" />

      <div v-else v-html="html" />
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
    htmlTag: {
        type: String,
        default: 'h2',
        validator: (value) => ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'div'].includes(value),
    },

    fontSize: {
        type: String,
        default: 'h2',
        validator: (value) => ['h1', 'h2', 'h3', 'h4', 'p', 'h-form'].includes(value),
    },

    fontFamily: {
        type: String,
        default: 'var(--f-family--primary)',
    },

    fontColor: {
        type: String,
        default: 'var(--c-black)',
    },

    alternativeColor: {
        type: String,
        default: 'var(--c-primary)',
    },

    preTitleColor: {
        type: String,
        default: 'var(--c-black)',
    },

    alignment: {
        type: String,
        default: 'left',
    },

    visibility: {
        type: String,
        default: 'both',
        validator: (value) => ['both', 'only-mobile', 'only-desktop'].includes(value),
    },

    text: {
        type: String,
        default: '',
    },

    pretitle: {
        type: String,
        default: '',
    },

    preTitleHasDecoration: {
        type: Boolean,
        default: false,
    },

    preTitlePadding: {
        type: String,
        default: '17px 31px 17px 0px',
    },

    preTitleFontWeight: {
        type: String,
        default: 'var(--f-weight--400)',
    },

    preTitleMarginBottom: {
        type: String,
        default: '18px',
    },

    /*
      Layout
    */
    isOnGrid: {
        type: Boolean,
        default: false,
    },

    grid: {
        type: String,
        default: 'full',
    },
});

const {
    fontColor,
    alignment,
    preTitleColor,
    fontFamily,
    preTitleMarginBottom,
    alternativeColor,
    text,
} = toRefs(props);

/*
  Replacements with Handlebars
*/
const recruiter = inject('recruiter', {});
const html = ref('');
const replacements = {
    ...recruiter.value,
    primary: '<span class="is-colored">',
    primaryEnd: '</span>',
};
watchEffect(() => {
    html.value = compileWithHandlebars(text.value, replacements);
});
</script>

<style lang="scss" scoped>
.atom-heading {
    /*
        Color schema
    */
    margin-bottom: var(--m-bottom--smallest);
    color: v-bind(fontColor);
    font-family: v-bind(fontFamily);
    font-weight: var(--f-weight--900);
    letter-spacing: 2.5px;
    text-align: v-bind(alignment);

    &.is-on-grid {
        @include wrapper-layout;
        @include grid-layout();

        row-gap: 0;

        & > * {
            @include default-content-columns;
        }
    }

    &.is-grid-narrow  {
        & > * {
            @include default-content-columns('narrow');
        }
    }

    &.is-visible-only-mobile {
        display: grid;

        @include tablet {
            display: none !important;
        }
    }

    &.is-visible-only-desktop {
        display: none;

        @include tablet {
            display: block;
        }
    }

    /*
        Font size
    */
    &.is-size-h1 {
        margin-bottom: var(--m-bottom--small);
        font-size: var(--f-size--h1);
        line-height: var(--l-height--h1);
    }

    &.is-size-h2 {
        margin-bottom: var(--m-bottom--smallest);
        font-size: var(--f-size--h2);
        letter-spacing: 2.5px;
        line-height: var(--l-height--h2);
    }

    &.is-size-h3 {
        font-size: var(--f-size--h3);
        line-height: var(--l-height--h3)
    }

    &.is-size-h4 {
        font-size: var(--f-size--h4);
        line-height: var(--l-height--h4)
    }

    &.is-size-p {
        font-size: var(--f-size--p);
        line-height: var(--l-height--p)
    }

    &.is-size-h-form {
        font-size: var(--f-size--h-form);
        font-weight: var(--f-weight--900);
        line-height: var(--l-height--h-form);
    }

    ::v-deep(.is-colored) {
        color: v-bind(alternativeColor);
    }

    &.no-letter-spacing {
        letter-spacing: 0;
    }
}

.atom-heading__pretitle {
    z-index: 3;
    margin-bottom: v-bind(preTitleMarginBottom);
    color: v-bind(preTitleColor);
    font-family: var(--f-family--primary);
    font-size: var(--f-size--p);
    font-weight: var(--f-weight--400);
    letter-spacing: 1.14px;
    line-height: var(--l-height--p);

    .has-decoration & {
        position: relative;

        p {
            @include fluid-simple('font-size', 28px, 38px);
            width: fit-content;
            padding: v-bind(preTitlePadding);
            background-color: var(--c-white);
            border-bottom-right-radius: 50px;
            border-top-right-radius: 50px;
            font-weight: v-bind(preTitleFontWeight);
            line-height: 38px;
        }
    }
}

.atom-heading__pretitle-decoratrion {
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: v-bind(preTitleColor);
    border-bottom-right-radius: 50px;
    border-top-right-radius: 50px;
}

.atom-heading__text {
    color: var(--c-black);
}
</style>
