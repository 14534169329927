<template>
  <NuxtLink
    v-if="!isStorybook && !isExternalLink"
    :to="computedPath"
    :rel="target === '_blank' ? 'noopener noreferrer' : null"
    :target="target"
    :aria-label="ariaLabel"
    class="atom-link"
    :class="{ 'router-link-active': isCurrentChildLink }"
  >
    <slot />
  </NuxtLink>
  <a
    v-else
    :href="computedPath"
    rel="noopener noreferrer"
    target="_blank"
    :aria-label="ariaLabel"
    class="atom-link"
  >
    <slot />
  </a>
</template>

<script setup>
const props = defineProps({
    link: {
        type: String,
        default: '',
        validator: (value) => value.length > 0,
    },

    target: {
        type: String,
        default: '_self',
        validator: (value) => ['_blank', '_self'].includes(value),
    },

    ariaLabel: {
        type: String,
        default: '',
    },

    disablePrefix: {
        type: Boolean,
        default: false,
    },
});

const isExternalLink = computed(
    () => props.link.includes('http://')
    || props.link.includes('https://')
    || props.link.includes('mailto:')
    || props.link.includes('tel:'),
);

const route = useRoute();
const isCurrentChildLink = computed(() => route.path.includes(props.link));
const { translate } = useTranslatedStoryblokLinks();
const computedPath = computed(() => {
    if (!isExternalLink.value && !props.disablePrefix) {
        const mapped = translate(props.link);
        return mapped;
    }

    return props.link;
});

/*
  Storybook
*/
const runtimeConfig = useRuntimeConfig();
const isStorybook = runtimeConfig.public.IS_STORYBOOK;
</script>
