<template>
  <NuxtLayout
    layout="default"
    :top-content="layoutProps.topContent"
    :intro-content="layoutProps.introContent"
    :step-number="layoutProps.stepNumber"
    :back-link="layoutProps.backLink"
    :next-links="layoutProps.nextLinks"
    :has-main-content="layoutProps.hasMainContent"
    :link-visibility="layoutProps.linkVisibility"
    :force-indicator-on-mobile="layoutProps.forceIndicatorOnMobile"
  >
    <NuxtPage
      class="__nuxt-page"
      :main-content="layoutProps.mainContent"
    />

    <BlockOverlayCookie
      :title="trackingData?.title"
      :is-locking="trackingData?.is_fullscreen"
      :text="trackingData?.text"
      :accept-all="trackingData?.accept_all_text"
      :accept-essential="trackingData?.policy_link_text"
      :gtm-ids="trackingData?.gtm_tags.map((tag) => tag.tag)"
      :link="trackingData?.policy_link?.cached_url"
    />
  </NuxtLayout>
</template>

<script setup>
import './layouts/default.vue';

const { locale, defaultLocale } = useI18n();
const layoutProps = reactive({
    mainContent: [],
    topContent: [],
    introContent: [],
    stepNumber: null,
    backLink: {},
    nextLinks: [],
    hasMainContent: false,
    linkVisibility: '',
    forceIndicatorOnMobile: false,
    recruiter: {},
    seo: {},
});

/*
  Dependencies
*/
const route = useRoute();
const storyblokApi = useStoryblokApi();
const strapiClient = useStrapiClient();

const { public: runtimeConfig } = useRuntimeConfig();
const { USE_STORYBLOK_DRAFT } = runtimeConfig;

/*
  Initialize variables
*/
const initialRequests = [];
const isStoryblok = route.query._storyblok;
const generalVersion = isStoryblok ? 'draft' : 'published';
const version = USE_STORYBLOK_DRAFT === 'true' ? 'draft' : generalVersion;
const computedPath = computed(() => {
    const replacedPath = route.path.replace(`/${locale.value}`, '');
    return replacedPath === '/' || replacedPath === '' ? '/home' : replacedPath;
});

const computedLanguage = computed(() => (locale.value === defaultLocale ? '' : locale.value));

const pseudoRecruiter = '1005218537362000';
const userHash = useCookie('user_hash', {
    maxAge: 60 * 60 * 24 * 30,
});
const recruiterHash = useCookie('recruiter_hash', {
    maxAge: 60 * 60 * 24 * 30,
});

provide('storyblokVersion', version);
provide('isStoryblokPreview', generalVersion === 'draft');

// Check if recruiter hash is set and if not set it
if (!recruiterHash.value) {
    recruiterHash.value = isStoryblok ? pseudoRecruiter : route.query.r;
}

// Check if user hash is set and if not set it and push request to count up visit
if (!userHash.value) {
    userHash.value = getUid();
    if (!isStoryblok) {
        initialRequests.push(
            () => strapiClient('/recruiters/countUpVisit', {
                method: 'POST',
                body: {
                    recruiterHash: recruiterHash.value,
                },
            }),
        );
    }
}

/*
  Get settings from storyblok
*/
const { error, data } = await useAsyncData('settings-data', async () => {
    // eslint-disable-next-line no-useless-catch
    try {
        console.log(recruiterHash.value);
        const [settings, recruiter, countUp] = await Promise.all([
            // Get settings from storyblok
            storyblokApi.get('cdn/stories/', {
                version,
                language: locale.value === defaultLocale ? '' : locale.value,
                starts_with: 'settings/',
                resolve_links: 'link',
            }).catch((err) => {
                throw new Error(`Storyblok API Error: ${err?.response?.data?.message || err?.error?.message || err.message}`);
            }),

            // Get recruiter data from strapi
            strapiClient('/recruiters/getOneByHash', {
                method: 'GET',
                params: {
                    hash: recruiterHash.value,
                },
            }).catch((err) => {
                console.log(err.error.message);
                throw new Error(`Strapi Client Error: ${err?.response?.data?.message || err?.error?.message || err.message}`);
            }),

            // Call count up visit if needed
            ...initialRequests.map((requestFunc) => requestFunc().catch((err) => {
                throw new Error(`Initial Request Error: ${err?.response?.data?.message || err?.error?.message || err.message}`);
            })),
        ]);

        return {
            settings,
            recruiter,
            countUp,
        };
    } catch (err) {
        throw err; // Re-throw to let `error` capture it
    }
}, {
    watch: [computedLanguage],
});

watch(() => error.value, (newValue) => {
    if (newValue) {
        useSentryError(newValue, false);

        // Extract and log meaningful details from the error
        const errorMessage = newValue?.response?.data?.message
                             || newValue
                             || 'An unknown error occurred.';

        // Optionally throw an enhanced error
        throw createError({
            statusCode: newValue?.response?.status || 500,
            statusMessage: `An error occurred: ${errorMessage}`,
        });
    }
}, { immediate: true });

// Set current recruiter data
const recruiter = shallowRef({});
provide('recruiter', recruiter);

watch(() => data.value?.recruiter, (newValue) => {
    if (newValue) {
        recruiter.value = {
            id: newValue.id,
            department: newValue.department,
            name: newValue.name,
            email: newValue.email,
            phonenumber: newValue.phonenumber,
            image: {
                src: newValue.image.url,
                alt: newValue.image.alternativeText,
            },
        };
    }
}, { immediate: true });

/*
  Get current page data from storyblok
*/
const { data: response, error: storyError } = await useAsyncData(
    `storyblok-story-data-${computedPath.value}-${locale.value}`,
    () => storyblokApi.get(
        `cdn/stories${computedPath.value}`,
        {
            version,
            language: computedLanguage.value,
            resolve_links: 'link',
        },
    ),
    {
        transform: (res) => {
            if (res.data?.story) {
                const { story } = res.data;
                const { content } = story;
                let nextLinks = [];

                if (content.nextLinks) {
                    if (isArray(content.nextLinks)) {
                        nextLinks = content.nextLinks.map((button) => ({
                            label: button.label,
                            link: button.link.cached_url,
                        }));
                    }
                }

                if (content.seo) {
                    setSEOData(content.seo);
                }

                return {
                    storyId: story.id,
                    mainContent: content.main_content,
                    topContent: content.top_content,
                    introContent: content.intro_content,
                    stepNumber: parseInt(content.stepNumber, 10),
                    backLink: content.backLink,
                    nextLinks,
                    hasMainContent: isArrayNotEmpty(content.main_content),
                    forceIndicatorOnMobile: content.forceIndicatorOnMobile,
                    translatedSlugs: story.translated_slugs,
                    seo: content.seo || {},
                    linkVisibility: content.linkVisibility ? content.linkVisibility : 'both',
                };
            }

            return {};
        },
        watch: [computedLanguage, computedPath],
    },
);

watch(() => response.value, (newValue) => {
    if (newValue) {
        Object.keys(newValue).forEach((key) => {
            layoutProps[key] = newValue[key];
        });

        const { translatedSlugs } = newValue;
        if (Array.isArray(translatedSlugs) && translatedSlugs.length > 0) {
            const slugsObj = {
                [defaultLocale]: {
                    slug: story.default_full_slug,
                },
            };

            translatedSlugs.forEach((slug) => {
                const { lang, path: storyblokPath } = slug;
                slugsObj[lang] = {
                    slug: storyblokPath,
                };
            });

            setI18nParams(slugsObj);
        }
    }
}, { immediate: true });

onMounted(() => {
    if (generalVersion === 'draft') {
        useStoryblokBridge(layoutProps.storyId, (evStory) => {
            layoutProps.mainContent = evStory.content.main_content;
            layoutProps.introContent = evStory.content.intro_content;
            layoutProps.topContent = evStory.content.top_content;
            layoutProps.linkVisibility = evStory.content.linkVisibility;
            layoutProps.forceIndicatorOnMobile = evStory.content.forceIndicatorOnMobile;
            layoutProps.stepNumber = parseInt(evStory.content.stepNumber, 10);
            layoutProps.nextLinks = isArrayNotEmpty(evStory.content.nextLinks)
                ? evStory.content.nextLinks.map((button) => ({
                    label: button.label,
                    link: button.link.cached_url.startsWith('/')
                        ? button.link.cached_url
                        : `/${button.link.cached_url}`,
                })) : [];
            layoutProps.backLink = evStory.content.backLink;
        });
    }
});

watch(() => storyError.value, (newValue) => {
    if (newValue) {
        throw createError({
            statusCode: 404,
            message: 'Page not found',
            fatal: true,
        });
    }
}, { immediate: true });

/*
  Cookie Banner
*/
const trackingData = computed(() => getStoryblokSetting('tracking'));
</script>
