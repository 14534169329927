<template>
  <div class="error-layout">
    <BlockLayoutHeader />
    <div class="error-layout__inner">
      <div class="error-layout__row">
        <AtomTextHeading v-bind="heading" />
        <div class="error-layout__code">
          {{ error.statusCode }}
        </div>
        <p class="error-layout__message">
          {{ error.statusCode === 404 ? messageText : error.message }}
        </p>
        <div class="error-layout__button">
          <UtilRouteLink :link="backLink">
            <AtomButton :text="backText" @click="handleError" />
          </UtilRouteLink>
        </div>
      </div>
    </div>
    <BlockLayoutFooter text="© Coop" :links="links" />
  </div>
</template>

<script setup>
defineProps({
    error: {
        type: Object,
        default: () => {},
    },
});

const { locale } = useI18n();
const localePath = useLocalePath();

const links = computed(() => {
    let impressumLabel;
    let datenschutzLabel;
    let impressumLink;
    let datenschutzLink;

    switch (locale) {
    case 'fr-ch':
        impressumLabel = 'Impressum';
        datenschutzLabel = 'Protection des données';
        impressumLink = '/fr-CH/impressum';
        datenschutzLink = 'https://www.coop.ch/fr/entreprise/protection-des-donnees.html';
        break;
    case 'it-ch':
        impressumLabel = 'Impressum';
        datenschutzLabel = 'Protezione dei dati';
        impressumLink = '/it-CH/impressum';
        datenschutzLink = 'https://www.coop.ch/it/azienda/protezione-dei-dati.html';
        break;
    default:
        impressumLabel = 'Impressum';
        datenschutzLabel = 'Datenschutz';
        impressumLink = '/impressum';
        datenschutzLink = 'https://www.coop.ch/de/unternehmen/datenschutz.html';
    }

    return [
        {
            label: impressumLabel,
            link: impressumLink,
        },
        {
            label: datenschutzLabel,
            link: datenschutzLink,
        },
    ];
});

const messageText = computed(() => {
    switch (locale.value) {
    case 'fr-CH':
        return 'Oups! Cette page n\'existe pas.';
    case 'it-CH':
        return 'Oops! Questa pagina non esiste.';
    default:
        return 'Oops! Diese Seite existiert nicht.';
    }
});

const backText = computed(() => {
    switch (locale.value) {
    case 'fr-CH':
        return 'CONTINUER VERS COOPJOBS.CH';
    case 'it-CH':
        return 'CONTINUA SU COOPJOBS.CH';
    default:
        return 'Zu coopjobs.ch';
    }
});

const backLink = computed(() => {
    switch (locale.value) {
    case 'fr-CH':
        return 'https://www.coopjobs.ch/fr.html';
    case 'it-CH':
        return 'https://www.coopjobs.ch/it.html';
    default:
        return 'https://www.coopjobs.ch/de.html';
    }
});

const heading = computed(() => {
    let text;
    let pretitle;

    switch (locale.value) {
    case 'fr-CH':
        text = 'LÀ OÙ LA <span class="is-colored">VIE</span> SE PASSE';
        pretitle = 'Travailler,';
        break;
    case 'it-CH':
        text = 'DOVE LA <span class="is-colored">VITA</span> ACCADE';
        pretitle = 'Lavorare,';
        break;
    default:
        text = 'WO DAS <span class="is-colored">LEBEN</span> PASSIERT';
        pretitle = 'Arbeiten,';
    }

    return {
        variant: 'default',
        isDisabled: true,
        preTitleHasDecoration: true,
        preTitleColor: 'var(--c-primary)',
        preTitlePadding: '10px 15px',
        preTitleFontWeight: 'var(--f-weight--700)',
        preTitleMarginBottom: '18px',
        htmlTag: 'h1',
        fontSize: 'h1',
        text,
        pretitle,
        isOnGrid: false,
        fontColor: 'var(--c-black)',
        alternativeColor: 'var(--c-primary)',
    };
});

const handleError = () => {
    const redirectPath = localePath('/');
    clearError({ redirect: redirectPath });
};
</script>

<style lang="scss" scoped>
.error-layout {
    .atom-heading {
        margin-bottom: 67px;
    }

    .block-layout-footer {
        position: absolute;
    }
}

.error-layout__inner {
    @include grid-layout();

    position: relative;
    height: 100%;
    padding: var(--page-padding);
    padding-top: 100px;

    @include tablet {
        align-items: center;
        padding-top: var(--page-padding);
    }
}

.error-layout__row {
    @include grid-columns(1, 4);

    @include tablet {
        @include grid-columns(2, 9);
    }
}

.error-layout__code {
    margin-bottom: 30px;
    color: var(--c-black);
    font-family: var(--f-family--primary);
    font-size: var(--f-size--h2);
    letter-spacing: 2.04px;
    line-height: var(--l-height--h2);
}

.error-layout__message {
    margin-bottom: 40px;
    color: var(--c-black);
    font-family: var(--f-family--secondary);
    font-size: var(--f-size--p);
    line-height: var(--l-height--p);

    @include tablet {
        margin-bottom: 0;
    }
}

.error-layout__button {

    @include tablet{
        position: absolute;
        right: var(--page-padding);
        bottom: var(--page-padding);
    }
}
</style>
