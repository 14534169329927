export const getStoryblokSetting = (group, name) => {
    if (!group) {
        throw createError({
            statusCode: '500',
            statusMessage: 'Fatal: Required Param group is missing @useStoryblokSettings',
        });
    }

    const nuxtData = useNuxtData('settings-data');

    const settingsStories = nuxtData?.data?.value?.settings?.data.stories;
    if (!settingsStories) return null;

    const settingsStory = settingsStories.find((story) => story.name.toLowerCase() === group);

    if (!settingsStory) {
        throw createError({
            statusCode: '500',
            statusMessage: `Fatal: No settings story found for ${group} @useStoryblokSettings`,
        });
    }

    const settingsStoryContent = settingsStory.content;

    if (name) {
        return settingsStoryContent[name];
    }

    return settingsStoryContent;
};

export const getStoryblokTranslation = (group, key, isRichtext = false) => {
    const nuxtData = useNuxtData('settings-data');
    const settingsStories = nuxtData?.data?.value?.settings?.data.stories;
    if (!settingsStories) return null;

    const settingsStory = settingsStories.find((story) => story.name.toLowerCase() === 'translations');
    const data = settingsStory.content;

    if (group && key) {
        if (!data[group]) return '';

        const item = data[group].filter(
            (e) => e.key === key,
        );

        if (item.length === 1) {
            if (isRichtext) return item[0].richtext_value;
            return item[0].value;
        }
    } else {
        if (group) {
            return data[group];
        }

        return data.translations;
    }

    return '';
};
