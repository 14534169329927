import { default as _91_46_46_46slug_93Sb3zmP9EPhMeta } from "/opt/atlassian/pipelines/agent/build/pages/[...slug].vue?macro=true";
import { default as component_45stubAHTfMazsbDMeta } from "/opt/atlassian/pipelines/agent/build/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.12_eslint@8.57.0_ioredis@5.4.1_magicast@0_lba6twkz7gw5qvbtvs4vocoboq/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubAHTfMazsbD } from "/opt/atlassian/pipelines/agent/build/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.12_eslint@8.57.0_ioredis@5.4.1_magicast@0_lba6twkz7gw5qvbtvs4vocoboq/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "slug___de-CH",
    path: "/de-CH/:slug(.*)*",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "slug___fr-CH",
    path: "/fr-CH/:slug(.*)*",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "slug___it-CH",
    path: "/it-CH/:slug(.*)*",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: component_45stubAHTfMazsbDMeta?.name,
    path: "/sitemap.xml",
    component: component_45stubAHTfMazsbD
  }
]