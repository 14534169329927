<template>
  <div
    class="ion-icon"
    :class="{
      'has-icon-color': iconColor,
      'has-size': iconSize,
    }"
  >
    <Suspense>
      <UtilRendererIcon
        v-if="iconName?.length > 0"
        :key="uniqueKey"
        :icon-name="iconName"
        class="ion-icon__svg"
      />
    </Suspense>
  </div>
</template>

<script setup>
const props = defineProps({
    iconName: {
        type: String,
        required: true,
    },

    iconColor: {
        type: String,
        default: null,
    },

    iconSize: {
        type: String,
        default: null,
    },
});

const {
    iconColor,
    iconSize,
} = toRefs(props);

/*
      Rerender the asnyc child component when the icon name changes.
    */
const uniqueKey = ref(getUid());

watch(() => props.name, () => {
    uniqueKey.value = getUid();
});
</script>

  <style lang="scss" scoped>
    .ion-icon {
        display: flex;

        ::v-deep(svg) {
            path {
                transition: fill 0.3s ease, stroke 0.3s ease, color 0.3s ease;
            }
        }

        &.has-icon-color {
            ::v-deep(path) {
                fill: v-bind(iconColor);
                transition: fill 0.3s ease, stroke 0.3s ease, color 0.3s ease;
            }
        }

        .has-error & {
            ::v-deep(path) {
                fill: var(--c-error) !important;
            }

        }
    }

    .ion-icon__svg {
        width: auto;

        .has-size & {
            height: v-bind(iconSize);
        }
    }
  </style>
