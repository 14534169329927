import revive_payload_client_cVzcSCCvCj from "/opt/atlassian/pipelines/agent/build/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.12_eslint@8.57.0_ioredis@5.4.1_magicast@0_lba6twkz7gw5qvbtvs4vocoboq/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_HAzpPHt0Sd from "/opt/atlassian/pipelines/agent/build/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.12_eslint@8.57.0_ioredis@5.4.1_magicast@0_lba6twkz7gw5qvbtvs4vocoboq/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_vA9Oj9o3pn from "/opt/atlassian/pipelines/agent/build/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.12_eslint@8.57.0_ioredis@5.4.1_magicast@0_lba6twkz7gw5qvbtvs4vocoboq/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_5xwUbOOWmP from "/opt/atlassian/pipelines/agent/build/node_modules/.pnpm/nuxt-site-config@2.2.15_magicast@0.3.4_rollup@4.19.0_vite@5.2.11_@types+node@20.14.12_sass@1._74lruossdgrzs4zydxld2plwzu/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_N7trAuV098 from "/opt/atlassian/pipelines/agent/build/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.12_eslint@8.57.0_ioredis@5.4.1_magicast@0_lba6twkz7gw5qvbtvs4vocoboq/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_GlNFcN2iFu from "/opt/atlassian/pipelines/agent/build/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.12_eslint@8.57.0_ioredis@5.4.1_magicast@0_lba6twkz7gw5qvbtvs4vocoboq/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_Q7ClS4afIz from "/opt/atlassian/pipelines/agent/build/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.12_eslint@8.57.0_ioredis@5.4.1_magicast@0_lba6twkz7gw5qvbtvs4vocoboq/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_cCK5nZc0al from "/opt/atlassian/pipelines/agent/build/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.12_eslint@8.57.0_ioredis@5.4.1_magicast@0_lba6twkz7gw5qvbtvs4vocoboq/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/opt/atlassian/pipelines/agent/build/.nuxt/components.plugin.mjs";
import prefetch_client_AAvraKpzta from "/opt/atlassian/pipelines/agent/build/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.12_eslint@8.57.0_ioredis@5.4.1_magicast@0_lba6twkz7gw5qvbtvs4vocoboq/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import strapi_3bg54Yl3me from "/opt/atlassian/pipelines/agent/build/node_modules/.pnpm/@nuxtjs+strapi@1.12.0_magicast@0.3.4_rollup@4.19.0/node_modules/@nuxtjs/strapi/dist/runtime/plugins/strapi.mjs";
import plugin_7EWVQWTrgc from "/opt/atlassian/pipelines/agent/build/node_modules/.pnpm/nuxt-lenis@1.2.3_magicast@0.3.4_react-dom@18.3.1_react@18.3.1__react@18.3.1_rollup@4.19.0/node_modules/nuxt-lenis/dist/runtime/plugin.mjs";
import plugin_Qa1h2am6Do from "/opt/atlassian/pipelines/agent/build/node_modules/.pnpm/@storyblok+nuxt@6.0.10/node_modules/@storyblok/nuxt/dist/runtime/plugin.mjs";
import i18n_uV0qiGdWOP from "/opt/atlassian/pipelines/agent/build/node_modules/.pnpm/@nuxtjs+i18n@8.3.1_magicast@0.3.4_rollup@4.19.0_vue@3.4.34_typescript@5.5.4_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import app_init_hybrid_5gOhL5QG1b from "/opt/atlassian/pipelines/agent/build/plugins/app-init-hybrid.js";
import app_init_client_lDwNIL1JCF from "/opt/atlassian/pipelines/agent/build/plugins/app-init.client.js";
import chunk_error_handler_wTHYgqOJwi from "/opt/atlassian/pipelines/agent/build/plugins/chunk-error-handler.js";
import sb_preview_757msyeJ0g from "/opt/atlassian/pipelines/agent/build/plugins/sb-preview.js";
import sentry_client_GoGQuZo4Et from "/opt/atlassian/pipelines/agent/build/plugins/sentry.client.js";
import vue_gtag_S6KjvYXIWb from "/opt/atlassian/pipelines/agent/build/plugins/vue-gtag.js";
export default [
  revive_payload_client_cVzcSCCvCj,
  unhead_HAzpPHt0Sd,
  router_vA9Oj9o3pn,
  _0_siteConfig_5xwUbOOWmP,
  payload_client_N7trAuV098,
  navigation_repaint_client_GlNFcN2iFu,
  check_outdated_build_client_Q7ClS4afIz,
  chunk_reload_client_cCK5nZc0al,
  components_plugin_KR1HBZs4kY,
  prefetch_client_AAvraKpzta,
  strapi_3bg54Yl3me,
  plugin_7EWVQWTrgc,
  plugin_Qa1h2am6Do,
  i18n_uV0qiGdWOP,
  app_init_hybrid_5gOhL5QG1b,
  app_init_client_lDwNIL1JCF,
  chunk_error_handler_wTHYgqOJwi,
  sb_preview_757msyeJ0g,
  sentry_client_GoGQuZo4Et,
  vue_gtag_S6KjvYXIWb
]