<template>
  <transition :name="isLocking ? 'fade-in' : 'slide-up'">
    <div
      v-if="!acceptedAll || !acceptedRequired"
      class="block-overlay-cookie"
      :class="{ 'is-locking': isLocking }"
    >
      <div class="block-overlay-cookie__inner">
        <div class="block-overlay-cookie__texts">
          <div v-if="title" class="block-overlay-cookie__title">
            <AtomTextHeading html-tag="div" font-size="h4" :text="title" font-color="var(--c-black)" />
          </div>

          <AtomTextRichtext :html="text" font-size="var(--f-size--h4)" line-height="var(--l-height--h4)" />
        </div>

        <div class="block-overlay-cookie__buttons">
          <UtilRouteLink
            v-if="link"
            class="block-overlay-cookie__button"
            :link="link"
          >
            <button
              :key="`cookie-banner-button-${index}`"
              type="button"
            >
              {{ acceptEssential }}
            </button>
          </UtilRouteLink>
          <button
            class="block-overlay-cookie__button"
            type="button"
            @click="setUsersChoice('accept-all')"
          >
            {{ acceptAll }}
          </button>
        </div>
      </div>
    </div>
  </transition>
</template>

<script setup>
const props = defineProps({
    isOpen: {
        type: Boolean,
        default: false,
    },

    isLocking: {
        type: Boolean,
        default: false,
    },

    title: {
        type: String,
        default: 'Cookie Consent',
    },

    text: {
        type: String,
        default: 'This website uses cookies to ensure you get the best experience on our website.',
    },

    link: {
        type: String,
        default: 'Learn more',
    },

    links: {
        type: Array,
        default: () => [],
    },

    acceptAll: {
        type: String,
        default: 'Accept all',
    },

    acceptEssential: {
        type: String,
        default: 'Accept essential',
    },

    gtmIds: {
        type: Array,
        default: null,
    },
});

/*
    Check for existing cookies and
    start tracking or show banner
*/
const showBanner = ref(props.isOpen);
const acceptedAll = useCookie('cookies-accepted');
const acceptedRequired = useCookie('cookies-accepted-required');

onMounted(() => {
    if (!acceptedAll.value && !acceptedRequired.value) {
        showBanner.value = true;
    }
});
/*
set cookies and hide banner and start tracking (if allowed)
*/
const setUsersChoice = (action) => {
    showBanner.value = false;

    switch (action) {
    case 'accept-all':
        acceptedAll.value = true;
        acceptedRequired.value = true;
        return;
    case 'accept-essential':
        acceptedRequired.value = true;
        break;
    default:
        break;
    }
};

</script>

<style lang="scss" scoped>
.block-overlay-cookie {
    @include z-index('cookie');

    position: fixed;
    right: var(--page-padding);
    bottom: var(--page-padding);
    display: flex;
    width: calc(100vw - (var(--page-padding) * 2));
    max-width: 330px;
    font-family: var(--f-family--teritary);

    ::v-deep(.atom-heading__text) {
        padding: 0;
        font-family: var(--f-family--teritary-bold);
        font-size: 24px;
        letter-spacing: 0;
        text-align: center;
    }

    ::v-deep(.atom-richtext__inner) {
        font-family: var(--f-family--teritary);
        font-size: 18px;
        line-height: 23px;
    }

    &.is-locking{
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        max-width: unset;
        height: 100dvh;
        align-items: center;
        justify-content: center;
        backdrop-filter: blur(5px);
        background: rgba(255, 255, 255, 0.75);
    }
}

.block-overlay-cookie__links {
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
    color: var(--c-primary);
    gap: 10px;
    text-decoration: underline;
}

.block-overlay-cookie__inner {
    overflow: hidden;
    border-radius: 6px;
    background: var(--c-white);
    box-shadow: 0 0 11px 0 rgba(245, 156, 0, .24)
}

.block-overlay-cookie__texts {
    @include fluid-simple('padding', 24px, 28px);

    width: calc(100vw - (var(--page-padding) * 2));
    max-width: 330px;
}

.block-overlay-cookie__title {
    margin-bottom: 10px;
}

.block-overlay-cookie__buttons {
    display: flex;
    flex-wrap: wrap;
    border-top: 1px solid var(--c-grey--light);
}

.block-overlay-cookie__button {
    @include fluid-simple('height', 50px, 60px);
    position: relative;

    display: flex;
    width: 50%;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 16px;
    font-weight: 700;

    button {
        color: var(--c-secondary);
    }

    &:after {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        height: 2px;
        background: var(--c-grey);
        content: '';
    }

    &:last-child {
        background: var(--c-secondary);
        color: var(--c-white);
        &:after {
            display: none;
        }
    }
}
</style>
