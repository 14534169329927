<!-- eslint-disable vue/no-v-html -->
<template>
  <div
    class="atom-richtext"
    :class="[
      `is-grid-${grid}`,
      { 'is-on-grid': isOnGrid },
    ]"
  >
    <div
      class="atom-richtext__inner"
      v-html="computedHtml"
    />
  </div>
</template>

<script setup>

const props = defineProps({
    html: {
        type: [String, Object],
        required: true,
        default: null,
    },

    fontSize: {
        type: String,
        default: 'var(--f-size--p)',
    },

    lineHeight: {
        type: String,
        default: 'var(--l-height--p)',
    },

    textColor: {
        type: String,
        default: 'var(--c-black)',
    },

    alignment: {
        type: String,
        default: 'left',
    },

    isOnGrid: {
        type: Boolean,
        default: false,
    },

    grid: {
        type: String,
        default: 'full',
    },
});

const {
    textColor, alignment, fontSize, lineHeight,
} = toRefs(props);

const { richTextResolver } = useStoryblokApi();
const recruiter = inject('recruiter', {});

const computedHtml = computed(() => {
    const res = (typeof props.html === 'object'
        ? richTextResolver.render(props.html) : props.html);

    return compileWithHandlebars(
        res,
        recruiter.value,
    );
});
</script>

<style lang="scss" scoped>
.atom-richtext{
    &.is-on-grid {
        @include wrapper-layout;
        @include grid-layout();

        margin-bottom: var(--m-bottom--small);
    }
}

.atom-richtext__inner {
    color: v-bind(textColor);
    font-family: var(--f-family--secondary);
    font-size: v-bind(fontSize);
    font-weight: var(--f-weight--400);
    line-height: v-bind(lineHeight);
    text-align: v-bind(alignment);

    .is-on-grid & {
        @include default-content-columns;
    }

    .is-grid-narrow & {
        @include default-content-columns('narrow');
    }

    ::v-deep(ul)  {
        @include fluid-simple('margin-bottom', 16px, 19px);
        display: inline-block;
        padding-left: 25px;

        li {
            position: relative;

            &:before {
                position: absolute;
                top: 14px;
                left: -15px;
                width: 4px;
                height: 4px;
                border-radius: 50%;
                background: v-bind(textColor);
                content: '';
            }
        }
    }

    ::v-deep(strong) {
        font-weight: var(--f-weight--900);
    }

    ::v-deep(b) {
        font-weight: var(--f-weight--900);
    }

    ::v-deep(p)  {
        margin: var(--f-size--p) 0;

        b, strong {
            font-family: var(--f-family--secondary);
        }

        &:last-child {
            margin: 0;
        }
    }

    ::v-deep(a) {
        color: var(--c-primary);
        text-decoration: underline;
    }
}
</style>
