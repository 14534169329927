<template>
  <component
    :is="htmlTag"
    class="atom-button"
    :type="buttonType"
    :disabled="isDisabled"
    :class="[
      `is-icon-${iconPosition}`,
      `is-size-${buttonSize}`,
      { 'has-icon': icon !== 'none' },
    ]"
    @click="onClick"
  >
    <span
      v-if="text"
      class="atom-button__text"
    >{{ text }}</span>

    <IonIcon
      v-if="icon !== 'none'"
      :icon-name="icon"
      class="atom-button__icon"
      :icon-size="iconSize"
    />
  </component>
</template>

<script setup>
const props = defineProps({
    /*
        Text
    */
    text: {
        type: String,
        default: '',

    },

    /*
        Styles
    */
    backgroundColor: {
        type: String,
        default: 'transparent',
    },

    textColor: {
        type: String,
        default: 'var(--c-secondary)',
    },

    hoverColor: {
        type: String,
        default: 'var(--c-secondary)',
    },

    borderColor: {
        type: String,
        default: 'var(--c-secondary)',
    },

    borderRadius: {
        type: String,
        default: 'var(--b-radius--full)',
    },

    buttonSize: {
        type: String,
        default: 'default',
        validator: (value) => [
            'small',
            'default',
        ].includes(value),
    },

    /*
        Icons
    */
    icon: {
        type: String,
        default: 'arrow-right',
    },

    iconSize: {
        type: String,
        default: '21px',
    },

    iconPosition: {
        type: String,
        default: 'right',
        validator: (value) => [
            'left',
            'right',
        ].includes(value),
    },

    /*
        States
    */
    isDisabled: {
        type: Boolean,
        default: false,
        validator: (value) => typeof value === 'boolean',
    },

    /*
        Functionality
    */
    callback: {
        type: Function,
        default: () => {},
    },

    htmlTag: {
        type: String,
        default: 'button',
        validator: (value) => [
            'button',
            'div',
        ].includes(value),
    },

    buttonType: {
        type: String,
        default: 'button',
        validator: (value) => [
            'button',
            'submit',
            'reset',
        ].includes(value),
    },
});

const onClick = () => {
    if (props.isDisabled) return;
    props.callback();
};

const {
    textColor,
    backgroundColor,
    borderColor,
    borderRadius,
    hoverColor,
} = toRefs(props);
</script>

<style lang="scss" scoped>
.atom-button__text {
    z-index: 1;
}

.atom-button__icon {
    z-index: 1;
    transition: all 0.3s var(--ease-in-out--sine);
}

.atom-button {
    @include fluid('padding-top', 12px, 12px, 12px);
    @include fluid('padding-bottom', 12px, 12px, 12px);
    @include fluid('padding-left', 20px, 20px, 20px);
    @include fluid('padding-right', 20px, 20px, 20px);

    position: relative;
    display: inline-flex;
    overflow: hidden;
    height: 100%;
    align-items: center;
    align-self: flex-start;
    border: none;
    border: 2px solid v-bind(borderColor);
    border-radius: v-bind(borderRadius);
    -webkit-appearance: none;
    background-color: v-bind(backgroundColor);
    color: v-bind(textColor);
    column-gap: 20px;
    font-family: var(--f-family--secondary-bold);
    font-size: var(--f-size--a);
    font-weight: var(--f-weight--900);
    line-height: var(--l-height--a);
    outline: none;
    transition: all 0.2s var(--ease-in-out--sine);

    &.is-size-small {
        padding: 8px 16px;

    }

    /*
        Icon
    */
    &.is-icon-left {
        .atom-button__icon {
            order: -1;
        }
    }

    /*
        States
    */
    @include only-hover {
        background-color: v-bind(hoverColor);
        color: var(--c-white);

        ::v-deep(.ion-icon) {
            path {
                stroke: var(--c-white);
            }
        }
    }

    &:disabled {
        opacity: 0.2;
        pointer-events: none;
    }

    .block-slider-slot__navigation & {
        &:disabled {
            opacity: 0;
        }
    }
}
</style>
