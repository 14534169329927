<template>
  <nav class="block-navigation-language">
    <ul class="block-navigation-language__list">
      <li
        v-for="lang in locales"
        :key="lang.code"
        class="block-navigation-language__list-item"
        :class="{ 'is-active': locale === lang.code }"
      >
        <NuxtLink
          v-if="!isStorybook"
          class="block-navigation-language__list-item-link"
          :to="processLocalePath(lang.code)"
          :disable-prefix="true"
        >
          {{ lang.name }}
        </NuxtLink>

        <div
          v-else
          class="block-navigation-language__list-item-link"
          @click="changeLocale(lang.code)"
          @keypress="changeLocale(lang.code)"
        >
          {{ lang.name }}
        </div>
      </li>
    </ul>
  </nav>
</template>

<script setup>
const props = defineProps({
    textColor: {
        type: String,
        default: 'var(--c-black)',
    },
});

const {
    textColor,
} = toRefs(props);

/*
  Internationalization
*/
const switchLocalePath = useSwitchLocalePath();
const { locale, locales } = useI18n();

const runtimeConfig = useRuntimeConfig();
const isStorybook = runtimeConfig.public.IS_STORYBOOK;

/*
  Handle home link
*/
const processLocalePath = (langCode) => {
    let path = switchLocalePath(langCode);

    // Remove query params
    path = path.replace(/\?.*$/, '');

    if (path.endsWith('/home')) {
        path = path.replace(/home$/, '');
    }

    return path;
};

/* Storybook Only */
const changeLocale = (newLocale) => {
    locale.value = newLocale;
};
</script>

<style lang="scss" scoped>
.block-navigation-language__list {
    display: inline-flex;
    color: v-bind(textColor);
    column-gap: 10px;
    font-family: var(--f-family--primary);
    font-weight: var(--f-weight--regular);
    letter-spacing: 0.85px;
}

.block-navigation-language__list-item {
    position: relative;
    display: flex;
    padding-top: 1px;
}

.block-navigation-language__list-item-link {
    cursor: pointer;
    font-size: 17px;
    line-height: 18px;
    text-transform: uppercase;
    transition: color 0.1s ease-in-out;

    .is-active & {
        color: var(--c-primary);
    }
}
</style>
