<template>
  <div class="block-layout-header">
    <IonIcon icon-name="logo" />
    <BlockNavigationLanguage />
  </div>
</template>

<script setup>

</script>

<style lang="scss" scoped>
.block-layout-header {
    @include z-index('header');

    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;

    @include fluid-simple('padding-left', 20px, 50px);
    @include fluid-simple('padding-right', 20px, 50px);

    padding-top: 40px;
    padding-bottom: 40px;

    @include tablet {
        padding-top: 22px;
        padding-bottom: 22px;
    }

    ::v-deep(.ion-icon__svg) {
        @include fluid-simple('width', 90px, 128px);
    }
}
</style>
